import React from 'react'
import { useTranslation } from 'react-i18next'

const Loading = () => {
  const { t } = useTranslation()

  return (
    <h4>
      {t('Ladataan...')}
    </h4>
  )
}

export default Loading
