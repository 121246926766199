import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, Link } from 'react-router-dom'

import Loading from '../components/Loading'
import Order, { isComplete } from '../components/Order'

import { searchOrder } from '../api'


const OrderList = ({ orders }) => orders.map(o => <Order condensed key={o.order_id} {...o} />)


const Orders = () => {
  const [ active, setActive ] = useState(null)
  const [ old, setOld ] = useState(null)
  const [ current, setCurrent ] = useState(null)
  const { t } = useTranslation()
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    setActive(null)
    setOld(null)
    searchOrder()
    .then(r => r.json())
    .then(r => {
      const activeStatus = r.orders.filter(o => !isComplete(o.order_status))
      const oldStatus = r.orders.filter(o => isComplete(o.order_status))
      const time = (o) => o.preorder_time || o.submitted || ''
      activeStatus.sort((a, b) => time(a) < time(b) ? -1 : 1)
      oldStatus.sort((a, b) => time(a) < time(b) ? 1 : -1)
      setActive(activeStatus)
      setOld(oldStatus)
      const c = (
        r.orders.find(o => o.dispatch_order_number === +id) ||
        r.orders.find(o => o.order_id === +id)
      )
      setCurrent(c)
      if (id && !c) {
        history.push('/app/orders')
      }
    })
  }, [history, id, setActive, setOld])
  
  if (active === null || old === null) {
    return <Loading />
  }

  if (current) {
    return (
      <>
        <Link to="/app/orders">&laquo; {t('Tilauslistaukseen')}</Link>
        <Order {...current} />
      </>
    )
  }

  const noOrders = <p>{t('Ei tilauksia')}</p>
  return (
    <>
      <h2>{t('Omat tilaukset')}</h2>
      <Tabs defaultActiveKey={active.length ? 'active': 'old'} variant="pills">
        <Tab eventKey="active" title={t('Ennakkotilaukset')}>
          {active.length ? (
            <OrderList orders={active} />
          ) : (
            noOrders
          )}
        </Tab>
        <Tab eventKey="old" title={t('Päättyneet')}>
          {old.length ? (
            <OrderList orders={old} />
          ) : (
            noOrders
          )}
        </Tab>
      </Tabs>
    </>
  )
}

export default Orders
