import React from 'react'
import { Col } from 'react-bootstrap'

import styles from './UserField.module.css'


const UserField = ({ label, value }) => (
  <Col className={styles.UserField} sm={6}>
    <label>{label}</label>
    <div>{value || ' '}</div>
  </Col>
)

export default UserField
