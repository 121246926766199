import React from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Counter from './Counter'
import Destination from './Destination'
import Icon from '../Icon'
import LocationPicker from '../LocationPicker'
import TimeSection from './TimeSection'
import TravelRestrictions from './TravelRestrictions'

import styles from './OrderEditor.module.css'


const FormInput = ({ id, onChange, state, type, ...rest }) => {
  const handleChange = (e) => onChange({[id]: e.target.value})

  return type === 'textarea' ? (
    <Form.Control as="textarea" onChange={handleChange} value={state[id] || ''} {...rest}/>
  ) : (
    <Form.Control onChange={handleChange} value={state[id] || ''} {...rest} />
  )
}


const OUTSIDE_AREA = 105

const AddressError = ({ message }) => (
  <Alert variant="danger">{message}</Alert>
)


const OrderEditor = ({ error, info, state, onChange, onSubmit, rights }) => {
  const { t } = useTranslation()

  const handleAddress = (address, key) => {
    console.log(address)
    const { address_components, city, formatted_address, street } = address || {}
    if (city && formatted_address && street) {
      onChange({[key]: {
        city,
        formatted_address,
        street,
      }})
      return
    }
    if (!address_components) {
      return
    }
    const getComponent = (type) => {
      const c = address_components.find(c => c.types.find(t => t === type))
      return c ? c.long_name : ''
    }
    const streetName = getComponent('route')
    const streetNumber = getComponent('street_number')
    const cityName = getComponent('locality') || getComponent('administrative_area_level_3')

    if (!cityName || !streetName) {
      return
    }

    onChange({[key]: {
      city: cityName,
      formatted_address,
      street: streetName + (streetNumber ? ' ' + streetNumber : ''),
    }})
  }

  const canSubmit = (
    state.pickup_address.latitude && state.destination_address.latitude &&
    !error.pickup_address && !error.destination_address &&
    (state.destination_type_code === undefined || state.destination_type_code)
  )

  const errorMsg = (code) => (
    code === OUTSIDE_AREA ? (
      t('Osoite on sallitun alueen ulkopuolella')
    ) : t('Osoitteen validointi epäonnistui')
  )

  return (
    <Form className={styles.OrderEditor} onSubmit={(e) => {
      e.preventDefault()
      onSubmit()
    }}>
      <Row>
        <Form.Group className={styles.Location} controlId="pickup" as={Col} sm={6}>
          <Form.Label>
            <Icon type="start" />
            {t('Lähtöosoite')}
          </Form.Label>
          <LocationPicker type="pickup_address" onChange={handleAddress} saved={info.address} value={state.pickup_address.formatted_address} />
          {error.pickup_address ? <AddressError message={errorMsg(error.pickup_address)} /> : null}
        </Form.Group>
        <Form.Group className={styles.Location} controlId="destination" as={Col} sm={6}>
          <Form.Label>
            <Icon type="destination" />
            {t('Valitse määränpää')}
          </Form.Label>
          <LocationPicker type="destination_address" onChange={handleAddress} saved={info.address} value={state.destination_address.formatted_address} />
          {error.destination_address ? <AddressError message={errorMsg(error.destination_address)} /> : null}
        </Form.Group>
      </Row>
      <TimeSection onChange={onChange} state={state} rights={rights} />
      <Destination onChange={onChange} state={state} />
      <h3>{t('Tilauksen lisätiedot')}</h3>
      <Row className={styles.Additional}>
        <Col sm={6}>
          <h4>{t('Saattajien lukumäärä')}</h4>
          <Counter
            id="number_of_helpers"
            max={Math.min(
              3 - (+state['number_of_other_adults'] || 0)
              - (+state['number_of_underage_children'] || 0),
              info.number_of_helpers_allowed
            )}
            onChange={onChange}
            state={state}
            title={t('Saattajia')}
          />
        </Col>
        <Col sm={6}>
          <h4>{t('Muiden matkustajien lukumäärä')}</h4>
          <Counter
            id="number_of_other_adults"
            max={
              3 - (+state['number_of_helpers'] || 0)
              - (+state['number_of_underage_children'] || 0)
            }
            onChange={onChange}
            state={state}
            title={t('Aikuisia')}
            />
          <Counter
            id="number_of_underage_children"
            max={
              3 - (+state['number_of_helpers'] || 0)
              - (+state['number_of_other_adults'] || 0)
            }
            onChange={onChange}
            state={state}
            title={t('Lapsia')}
          />
        </Col>
        <TravelRestrictions info={info} onChange={onChange} state={state} />
      </Row>
      <Form.Group as={Row} controlId="message">
        <Col sm={6}>
          <Form.Label as={'h4'}>{t('Viesti kuljettajalle')}</Form.Label>
        </Col>
        <Col sm={6}>
          <FormInput id="message" type="textarea" state={state} maxLength={256} onChange={onChange} />
        </Col>
      </Form.Group>
      <Row>
        <Col className={styles.Confirm} sm={{offset: 4, span: 4}}>
          <Button block disabled={!canSubmit} variant="success" type="submit">{t('Vahvista tilaus')}</Button>
          <Link to="/app/home" className={styles.Cancel}>{t('Peruuta')}</Link>
        </Col>
      </Row>
    </Form>
  )
}

export default OrderEditor
