import React, { useEffect } from 'react'
import moment from 'moment'
import Datetime from 'react-datetime'
import 'moment/locale/fi'
import 'react-datetime/css/react-datetime.css'
import { useState } from 'react'


const YESTERDAY = moment().subtract(1, 'days').endOf('day')


const DateTime = ({ id, onChange, placeholder, value, ...rest }) => {
  const [ state, setState ] = useState(value)

  const handleChange = (v) => {
    setState(v)
    if (moment.isMoment(v)) {
      onChange(v)
    }
  }

  const handleBlur = () => {
    if (state && !moment.isMoment(state)) {
      setState(value)
    }
  }

  useEffect(() => {
    setState(value)
  }, [setState, value])

  return (
    <Datetime
      id={id}
      dateFormat="D.M.Y"
      locale="fi"
      timeFormat="H:mm"
      inputProps={{
        className: 'form-control',
        onBlur: handleBlur,
      }}
      isValidDate={(c) => c && c.isValid() && c.isAfter(YESTERDAY)}
      onChange={handleChange}
      renderInput={(props) => (
        <input {...props} value={state ? props.value : ''} />
      )}
      value={state || placeholder}
      {...rest}
    />
  )
}

export default DateTime
