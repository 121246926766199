import React, { useEffect, useContext } from 'react'
import { Redirect } from 'react-router'

import Loading from '../components/Loading'

import { ACTION_LOGOUT, LoginContext } from '../App'

import { logOut } from '../api'



const Home = () => {
  const loginState = useContext(LoginContext)

  useEffect(() => {
    if (loginState.loggedIn) {
      logOut().then(() => loginState.dispatch({type: ACTION_LOGOUT}))
    }
  }, [loginState])
  
  if (loginState.loggedIn) {
    return <Loading />
  }

  return (
    <Redirect to="/login/login" />
  )
}

export default Home
