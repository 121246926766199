import React, { useContext } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import DateTime from '../DateTime'
import Icon from '../Icon'
import { TravelType, TravelsAvailable } from '../TravelRight'

import { AREA_KELA, AREA_SOITE, LoginContext } from '../../App'


const getPlaceholders = ({ isKela, isSoite, state, t }) => {
  const fmt = 'D.M.Y H:mm'
  if (isSoite) {
    const earliest = moment().add(90, 'minutes')
    const datetimePlaceholder = earliest.format(fmt)
    return {datetimePlaceholder}
  }
  if (isKela) {
    const datetimePlaceholder = !state.datetime && state.datetimeEnd && state.travelTime ? (
      state.datetimeEnd.clone().add(-state.travelTime, 'seconds').format(fmt)
    ) : ''
    const datetimeEndPlaceholder = !state.datetimeEnd && state.datetime && state.travelTime ? (
      state.datetime.clone().add(state.travelTime, 'seconds').format(fmt)
    ) : ''
    return {
      datetimePlaceholder,
      datetimeEndPlaceholder,
    }
  }
  return {
    datetimePlaceholder: t('Nyt')
  }
}


const ensureAfter = (d, e) => {
  if (d && d.isBefore(e)) {
    return e.clone().add(1, 'minutes')
  }
  return d
}

export const combineDateTimes = ({datetime, datetimeEnd, earliest, travelTime}) => {
  datetime = datetime || (datetimeEnd ? datetime : moment())
  datetime = ensureAfter(datetime, earliest)
  datetimeEnd = ensureAfter(datetimeEnd, earliest)
  return {
    datetime: !datetime && datetimeEnd && travelTime ? (
      datetimeEnd.clone().add(-travelTime, 'seconds')
    ) : datetime,
    datetimeEnd: !datetimeEnd && datetime && travelTime ? (
      datetime.clone().add(travelTime, 'seconds')
    ) : datetimeEnd,
  }
}


const TimeSection = ({ state, onChange, rights }) => {
  const { t } = useTranslation()
  const { soteArea } = useContext(LoginContext)
  const isKela = soteArea.soteAreaId === AREA_KELA
  const isSoite = soteArea.soteAreaId === AREA_SOITE
  const {
    datetimePlaceholder,
    datetimeEndPlaceholder,
  } = getPlaceholders({isKela, isSoite, state, t})

  const handleDatetime = (datetime) => {
    onChange({datetime, datetimeEnd: null})
  }

  const handleDatetimeEnd = (datetimeEnd) => {
    onChange({datetime: null, datetimeEnd})
  }

  const getTravelRight = () => {
    const selected = rights.find((t) => t.travel_right_id === +state.travel_right_id)
    return selected || rights[0]
  }

  const handleTravelRight = (e) => {
    const { travel_right_id } = rights[e.target.selectedIndex || 0]
    onChange({travel_right_id})
  }

  const handleDatetimeOpen = () => {
    if (!state.datetime && !state.datetimeEnd) {
      handleDatetime(moment())
    }
  }

  const handleDatetimeEndOpen = () => {
    if (!state.datetime && !state.datetimeEnd) {
      handleDatetimeEnd(moment())
    }
  }

  return (
    <Row>
      <Form.Group controlId="pickup_time" as={Col} sm={6}>
        <Form.Label>
          <Icon type="time" />
          {isKela ? (
            t('Lähtöaika')
          ) : t('Arvioitu aika')}
        </Form.Label>
        <DateTime
          id="pickup_time_picker"
          onChange={handleDatetime}
          onOpen={handleDatetimeOpen}
          placeholder={datetimePlaceholder}
          value={state.datetime}
          />
      </Form.Group>
      {isKela ? (
        <Form.Group controlId="arrival_time" as={Col} sm={6}>
          <Form.Label>
            <Icon type="time" />
            {t('Saapumisaika')}
          </Form.Label>
          <DateTime
            id="arrival_time_picker"
            onChange={handleDatetimeEnd}
            onOpen={handleDatetimeEndOpen}
            placeholder={datetimeEndPlaceholder}
            value={state.datetimeEnd}
          />
        </Form.Group>
      ) : null}
      {isSoite ? (
        <Form.Group controlId="right" as={Col} sm={6}>
          <Form.Label>
            <Icon type="placeholder" />
            {t('Käytettävä matkaoikeus')} <TravelsAvailable {...getTravelRight()} /> {t('jäljellä')}
          </Form.Label>
          <Form.Control as="select" custom onChange={handleTravelRight}>
            {rights.map(({ order_type, travel_right_id }) => (
              <option key={travel_right_id}>{TravelType({order_type})}</option>
            ))}
          </Form.Control>
        </Form.Group>
      ) : null}
    </Row>
  )
}

export default TimeSection
