import React, { useContext, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Loading from '../components/Loading'
import NavButton from '../components/NavButton'
import UserField from '../components/UserField'

import { userInfo } from '../api'
import { LoginContext } from '../App'

import styles from './Home.module.css'


const Home = () => {
  const [ user, setUser ] = useState(null)
  const { t } = useTranslation()

  const { soteArea } = useContext(LoginContext)

  useEffect(() => {
    userInfo()
    .then(r => r.json())
    .then(r => setUser(r.customer))
  }, [setUser])

  if (!user) {
    return <Loading />
  }

  return (
    <div id={styles.Home}>
      <h4>{t('Omat tiedot')}</h4>
      <Row>
        <UserField label={t('Asiakastiedot')} value={user.first_name + ' ' + user.last_name} />
      </Row>
      <hr />
      <div className={styles.Info}>
        <strong>
          <a href={soteArea.contactsUrl} rel="noopener noreferrer" target="_blank">
            {t('Asiakaspalvelu')}
          </a>
        </strong>
        <div>
          <a href={soteArea.instructionsUrl} rel="noopener noreferrer" target="_blank">
            {t('Yhteystiedot ja ohjeet')}
          </a>
        </div>
      </div>
      <div className={styles.Orders}>
        <NavButton to="/app/order" variant="primary" block>{t('Tilaa kyyti')}</NavButton>
        <NavButton to="/app/orders/" variant="link" block>{t('Tilauksesi')}</NavButton>
      </div>
      <div className={styles.Logout}>
        <Link to="/app/logout">{t('Kirjaudu ulos palvelusta')}</Link>
      </div>
    </div>
  )
}

export default Home
