import React, { useContext, useState, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'

import Loading from '../components/Loading'
import OrderEditor from '../components/OrderEditor/OrderEditor'

import { order, searchOrder, travelRights, userInfo, validate } from '../api'
import { AREA_KELA, AREA_SOITE, LoginContext } from '../App'
import { combineDateTimes } from '../components/OrderEditor/TimeSection'
import { Alert } from 'react-bootstrap'


const formatAddress = (a) => {
  return {
    formatted_address: a.street + ', ' + a.city,
    ...a,
  }
}

const copyOrder = (o) => {
  if (!o) {
    return {
      destination_address: {formatted_address: ''},
      pickup_address: {formatted_address: ''},
    }
  }

  const {
    message,
    number_of_children,
    number_of_helpers,
    number_of_other_adults,
    sote_properties,
  } = o
  const destination_address = formatAddress(o.destination_address)
  const pickup_address = formatAddress(o.pickup_address)
  return {
    destination_address,
    message,
    number_of_children,
    number_of_helpers,
    number_of_other_adults,
    pickup_address,
    sote_properties,
  }
}


const orderReducer = (state, change) => {
  return state ? {...state, ...change} : change
}


const Order = () => {
  const { t } = useTranslation()
  const [ error, setError ] = useState({})
  const [ info, setInfo ] = useState([])
  const [ state, dispatch ] = useReducer(orderReducer, null)
  const [ rights, setRights ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const { id } = useParams()
  const history = useHistory()
  const { soteArea } = useContext(LoginContext)
  const isKela = soteArea.soteAreaId === AREA_KELA
  const isSoite = soteArea.soteAreaId === AREA_SOITE

  const earliestDateTime = () => (
    isSoite ? (
      moment().add(90, 'minutes')
    ) : (isKela ? moment().add(120, 'minutes') : null)
  )

  useEffect(() => {
    travelRights().then(async (r) => {
      const rights = await r.json()
      setRights(rights.travel_right || [])
    })
    userInfo().then(async (r) => {
      const info = await r.json()
      const {
        address,
        customer_specific_restrictions,
        number_of_helpers_allowed
      } = info.customer || {
        address: [],
        number_of_helpers_allowed: 0,
      }
      setInfo({address, customer_specific_restrictions, number_of_helpers_allowed})

      if (+id) {
        const ordersResp = await searchOrder()
        const { orders } = await ordersResp.json()
        const order = (
          orders.find((o) => o.dispatch_order_number === +id) ||
          orders.find((o) => o.order_id === +id)
        )

        if (order) {
          dispatch(copyOrder(order))
          return
        }
      }

      dispatch({
        ...copyOrder(),
        sote_properties: customer_specific_restrictions || [],
        destination_type_code: isKela ? null : undefined,
      })
    })
  }, [id, setRights, dispatch, isKela])

  if (!state || !rights || loading) {
    return <Loading />
  }

  const handleChange = (change) => {
    setError({...error, message: null, messageMore: null})
    if (change.datetime) {
      if (earliestDateTime() && change.datetime.isBefore(earliestDateTime())) {
        change.datetime = earliestDateTime().add(1, 'minutes')
      }
    }
    if (change.datetimeEnd) {
      if (earliestDateTime() && change.datetimeEnd.isBefore(earliestDateTime())) {
        change.datetimeEnd = earliestDateTime().add(1, 'minutes')
      }
    }
    console.log(change)
    dispatch(change)
    if (change.pickup_address) {
      const { destination_address } = state
      validate({
        pickup_address: change.pickup_address,
        destination_address,
        calculate_travel_time: !!destination_address.street,
      }).then(async (r) => {
        const data = await r.json()
        if (data.validation_result_pickup_address) {
          setError({...error, pickup_address: data.validation_result_pickup_address})
        } else {
          const pickup_address = {...change.pickup_address, ...data.pickup_address}
          const travelTime = data.travel_time && !data.travel_time.error_desc ? (
            data.travel_time.time_s
          ) : null
          console.log(pickup_address, travelTime)
          dispatch({pickup_address, travelTime})
          setError({...error, pickup_address: false})
        }
      })
    }
    if (change.destination_address) {
      const { pickup_address } = state
      validate({
        pickup_address,
        destination_address: change.destination_address,
        calculate_travel_time: !!pickup_address.street,
      }).then(async (r) => {
        const data = await r.json()
        if (data.validation_result_destination_address) {
          setError({...error, destination_address: data.validation_result_destination_address})
        } else {
          const destination_address = {...change.destination_address, ...data.destination_address}
          const travelTime = data.travel_time && !data.travel_time.error_desc ? (
            data.travel_time.time_s
          ) : null
          console.log(destination_address, travelTime)
          dispatch({destination_address, travelTime})
          setError({...error, destination_address: false})
        }
      })
    }
  }

  const handleSubmit = () => {
    const { datetime, datetimeEnd, travelTime, ...rest } = state
    const combinedTimes = isKela ? (
      combineDateTimes({
        datetime,
        datetimeEnd,
        earliest: earliestDateTime(),
        travelTime,
      })
    ) : {datetime, datetimeEnd}

    const preorder_time = combinedTimes.datetime ? (
      combinedTimes.datetime.format()
     ) : (
       earliestDateTime() ? earliestDateTime().add(1, 'minutes').format() : null
     )

    const arrival_time = isKela ? (
      combinedTimes.datetimeEnd ? combinedTimes.datetimeEnd.format() : preorder_time
    ) : combinedTimes.datetimeEnd ? (
      combinedTimes.datetimeEnd.format()
    ) : null

    const req = {
      arrival_time,
      preorder_time,
      travel_right_id: rights && rights[0] ? rights[0].travel_right_id : 0, // default fallback
      ...rest
    }

    setLoading(true)

    order(req).then(async (r) => {
      const order = await r.json()
      console.log(order)
      history.push('/app/orders/' + t.order_id)
    }).catch((e) => {
      console.error(e)
      setLoading(false)
      setError({
        ...error,
        message: t('Tilauksen lähetys epäonnistui. Tarkista täyttämäsi tiedot.'),
        messageMore: earliestDateTime() ? (
          t('Tarkista että lähtöaika on riittävän kaukana tulevaisuudessa.')
        ) : null
      })
    })
  }

  return (
    <>
      <h2>{t('Tilaa uusi matka')}</h2>
      <OrderEditor error={error} info={info} state={state} onChange={handleChange} onSubmit={handleSubmit} rights={rights} />
      {error && error.message ? (
        <Alert variant="danger">
          <center>
            {error.message}
            {error.messageMore ? <br /> : null}
            {error.messageMore}
          </center>
        </Alert>
      ) : null}
    </>
  )
}

export default Order
