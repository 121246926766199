import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import styles from './NavButton.module.css'


const NavButton = ({ children, disabled, to, variant, ...rest }) => (
  <Button as={Link} className={styles.button} disabled={disabled} to={to} variant={disabled ? 'secondary' : variant} {...rest}>
    {children}
  </Button>
)

export default NavButton
