import React, { useContext } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { LoginContext } from '../App'

import styles from './Navi.module.css'


const Branding = () => {
  // TODO: get sote area from context, switch based on it
  return (
    <Navbar.Brand as={Link} to="/app/home">
      <img alt="Taksihelsinki logo" src="/th-logo.png" width="224px" height="25px" />
    </Navbar.Brand>
  )
}


const Navi = ({ active }) => {
  const { t } = useTranslation()

  const { soteArea } = useContext(LoginContext)

  return (
    <Navbar id={styles.Navi} expand="md" variant="dark">
      <Branding />
      <Navbar.Toggle aria-controls="main-nav" />
      <Navbar.Collapse id="main-nav">
        <Nav className="ml-auto">
          <Nav.Link active={active === 'order'} className={styles.Order} as={Link} to="/app/order">{t('Tilaa kyyti')}</Nav.Link>
          <Nav.Link active={active === 'home'} as={Link} to="/app/home">{t('Koti')}</Nav.Link>
          <Nav.Link active={active === 'user'} as={Link} to="/app/user">{t('Omat tiedot')}</Nav.Link>
          {soteArea ? (
            <Nav.Link className={styles.CustomerService} target="_blank" href={soteArea.contactsUrl}>
              {t('Asiakaspalvelu')}
            </Nav.Link>
          ) : null}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Navi
