/* backend requests and session state */

import fetch from 'node-fetch'
import { v4 as uuidv4 } from 'uuid'


const MAJOR_VERSION = +process.env.REACT_APP_MAJOR
const MINOR_VERSION = +process.env.REACT_APP_MINOR
const BUILD = +process.env.REACT_APP_BUILD
const APP_TOKEN = process.env.REACT_APP_TOKEN
const API_URL = process.env.REACT_APP_URL


// TODO: use sessionStorage?
const storage = localStorage

const getDeviceId = () => {
  let value = storage.getItem('DEVICE_ID')
  if (!value) {
    value = uuidv4()
    storage.setItem('DEVICE_ID', value)
  }
  return value
}

const DEVICE_ID = getDeviceId()

let SESSION_ID = +storage.getItem('SESSION_ID')


export const loggedIn = () => {
  return !!(DEVICE_ID && SESSION_ID)
}


export const logIn = (session_id) => {
  SESSION_ID = +session_id
  storage.setItem('SESSION_ID', session_id)
}


export const logOut = () => logout().catch(() => {
  return true
}).then(() => {
  storage.removeItem('SESSION_ID')
  SESSION_ID = null
})


const post = (path, json) => fetch(API_URL + path, {
  method: 'POST',
  headers: {'Content-Type': 'application/json'},
  body: JSON.stringify(json)
}).then((r) => {
  if (r.status === 401 || r.status === 403) {
    storage.removeItem('SESSION_ID')
    SESSION_ID = null
    throw Error(r.statusText)
  }
  return r
})

export const getActivationCode = (phone_number) => post('getactivationcode', {
  app_token: APP_TOKEN,
  device_id: DEVICE_ID,
  phone_number,
  language: 'Fi', // TODO: how to change?
  platform: 'Web',
  os_version: '',
  major_version: MAJOR_VERSION,
  minor_version: MINOR_VERSION,
  build: BUILD,
})

export const setActivationCode = (activation_code) => post('setactivationcode', {
  apptoken: APP_TOKEN,
  device_id: DEVICE_ID,
  activation_code,
})

export const phoneLogin = (login_token) => post('phonelogin', {
  apptoken: APP_TOKEN,
  device_id: DEVICE_ID,
  login_token,
  language: 'Fi', // TODO: how to change?
  platform: 'Web',
  os_version: '',
  major_version: MAJOR_VERSION,
  minor_version: MINOR_VERSION,
  build: BUILD,
})

export const logout = () => post('logout', {
  device_id: DEVICE_ID,
  session_id: SESSION_ID,
})

export const soteAreaInfo = () => post('soteareainfo', {
  device_id: DEVICE_ID,
  session_id: SESSION_ID,
})

export const travelRights = () => post('travelrights', {
  device_id: DEVICE_ID,
  session_id: SESSION_ID,
})

export const userInfo = () => post('userinfo', {
  device_id: DEVICE_ID,
  session_id: SESSION_ID,
})

export const searchOrder = () => post('searchorder', {
  device_id: DEVICE_ID,
  session_id: SESSION_ID,
})

export const validate = (order) => post('validate', {
  device_id: DEVICE_ID,
  session_id: SESSION_ID,
  ...order,
})

export const order = (order) => post('order', {
  device_id: DEVICE_ID,
  session_id: SESSION_ID,
  ...order,
})

export const cancelOrder = (order_id) => post('cancelorder', {
  device_id: DEVICE_ID,
  session_id: SESSION_ID,
  order_id,
})
