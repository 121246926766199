import React, { useContext } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { AREA_KELA, LoginContext } from '../../App'


const Destination = ({ onChange, state }) => {
  const { t } = useTranslation()
  const { soteArea } = useContext(LoginContext)
  const isKela = soteArea.soteAreaId === AREA_KELA

  const destinations = {
    J: t('Julkinen terveydenhuollon yksikkö'),
    Y: t('Yksityinen terveydenhuollon yksikkö'),
    O: t('Ostopalvelumatkat'),
    K: t('Kelan järjestämä kuntoutus'),
  }

  const reasons = {
    '104': t('Sairaus'),
    '105': t('Liikenneolosuhteet'),
  }
  const reasonTypes = Object.keys(reasons)

  const getReason = (id) => {
    const prop = state.sote_properties.find((p) => p.sote_property_id === +id)
    return prop ? prop.value : false
  }

  const handleReason = (id) => {
    const sote_properties = state.sote_properties.filter(
      (p) => !reasonTypes.find(t => p.sote_property_id === +t)
    ).concat({
      sote_property_id: +id,
      value: true,
    })
    onChange({sote_properties})
  }

  return isKela ? (
    <Row>
      <Col sm={6}>
        <h3>{t('Matkan kohde')}</h3>
        {Object.keys(destinations).map((id) => (
          <Form.Group controlId={'destination' + id} key={id}>
            <Form.Check
              checked={state.destination_type_code === id}
              type="radio"
              label={destinations[id]}
              onChange={() => onChange({destination_type_code: id})}
            />
          </Form.Group>
        ))}
      </Col>
      <Col sm={6}>
        <h3>{t('Matkan aihe')}</h3>
        {Object.keys(reasons).map((id) => (
          <Form.Group controlId={'reason' + id} key={id}>
            <Form.Check
              checked={getReason(id)}
              type="radio"
              label={reasons[id]}
              onChange={() => handleReason(id)}
            />
        </Form.Group>
      ))}
      </Col>
    </Row>
  ) : null
}

export default Destination
