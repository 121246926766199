import React, { useContext } from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { AREA_SOITE, LoginContext } from '../App'

import styles from './TravelRight.module.css'


export const TravelType = ({ order_type }) => {
  const { t } = useTranslation()
  return {
    '1': t('VPL virkistysmatka'),
    '2': t('VPL opiskelumatka'),
    '3': t('VPL työmatka'),
    '4': t('SHL'),
    '5': t('SHL veteraani'),
    '6': t('EHL'),
    '8': t('SHL sotainvalidi'),
    '1000000': t('C'),
    '1000001': t('VPL virkistysmatka'),
    '1000002': t('VPL opiskelumatka'),
    '1000003': t('VPL työmatka'),
    '1000004': t('SHL'),
    '1000005': t('SHL veteraani'),
    '1000006': t('EHL'),
    '1000007': t('B'),
    '1000008': t('SHL sotainvalidi'),
    '1000009': t('VPL'),
    '1000010': t('SHL mielenterveys'),
    '1000011': t('SHL alaikäinen'),
  }[order_type.toString()] || order_type.toString()
}


export const TravelsAvailable = ({ saldos, trip_count }) => (
  (trip_count - (saldos ? saldos.trips_booked : 0)) + '/' + trip_count
)

const getNextMonth = ({ expiry_date, quick_trip_count, trip_count }) => {
  if (expiry_date && DateTime.fromISO(expiry_date) <= DateTime.local().endOf('month')) {
    return {
      quick_trip_count: 0,
      trip_count: 0
    }
  }
  return {quick_trip_count, trip_count}
}


const TravelRight = ({ expiry_date, order_type, quick_trip_count, saldos, trip_count }) => {
  const { t } = useTranslation()
  const { soteArea } = useContext(LoginContext)
  const { quickTrips, quickTripsNextMonth, tripsBookedNextMonth } = saldos || {
    quickTrips: 0,
    quickTripsNextMonth: 0,
    tripsBookedNextMonth: 0,
  }
  const next = getNextMonth({expiry_date, quick_trip_count, trip_count})
  return (
    <Col className={styles.TravelRight} sm={6}>
      <h4><TravelType order_type={order_type} /></h4>
      <strong>{t('Tässä kuussa')}</strong>
      <div><TravelsAvailable saldos={saldos} trip_count={trip_count} /> {t('matkaa jäljellä')}</div>
      {soteArea.soteAreaId !== AREA_SOITE ? (
        <div>{quick_trip_count - quickTrips}/{quick_trip_count} {t('pikatilausta jäljellä')}</div>
        ) : null}
      <strong>{t('Ensi kuussa')}</strong>
      <div>{next.trip_count - tripsBookedNextMonth}/{next.trip_count} {t('matkaa jäljellä')}</div>
      {soteArea.soteAreaId !== AREA_SOITE ? (
        <div>{next.quick_trip_count - quickTripsNextMonth}/{next.quick_trip_count} {t('pikatilausta jäljellä')}</div>
      ) : null}
    </Col>
  )
}

export default TravelRight
