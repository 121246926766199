import React from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'

import styles from './OrderEditor.module.css'


const Counter = ({ id, max, onChange, state, title }) => {
  const value = +state[id] || 0
  const handleChange = (value) => onChange({[id]: value})

  return (
    <Form.Group className={styles.Counter} controlId={id}>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>{title}</InputGroup.Text>
          <Button disabled={+value <= 0} onClick={() => handleChange(value - 1)}>-</Button>
        </InputGroup.Prepend>
        <Form.Control
          min={0}
          max={max}
          type="number"
          value={value} onChange={(e) => handleChange(+e.target.value)}
        />
        <InputGroup.Append>
          <Button disabled={max <= +value} onClick={() => handleChange(value + 1)}>+</Button>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>
  )
}

export default Counter
