import React, { useContext } from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Restriction from './Restriction'

import { AREA_KELA, LoginContext } from '../../App'


export const WHEELCHAIR = 1
export const STRETCHER = 2
export const SEDAN = 3
const GUIDE_DOG = 4
//const OWN_TAXI = 6
export const TRAVELS_ALONE = 101
export const STAIRCASE_HELPER = 204


const TravelRestrictions = ({ info, onChange, state }) => {
  const { t } = useTranslation()
  const { soteArea } = useContext(LoginContext)
  const isKela = soteArea.soteAreaId === AREA_KELA

  return (
    <>
      <Col sm={6}>
        <h4>{t('Ajoneuvo ja lisätarpeet')}</h4>
      </Col>
      <Col sm={6}>
        {t('Valitse ajoneuvotyyppi ja lisätarpeet. Voit valita vain yhden ajoneuvotyypin per tilaus sekä useampia lisätarpeita.')}
      </Col>
      <Col sm={6}>
        <h4>{t('Ajoneuvotyyppi')}</h4>
        {isKela ? (
          <>
            <Restriction id={WHEELCHAIR} info={info} label={t('Esteetön ajoneuvo')} onChange={onChange} radio={false} state={state} />
            <Restriction id={STRETCHER} info={info} label={t('Paariauto')} onChange={onChange} radio={false} state={state} />
          </>
        ) : (
          <>
            <Restriction id={WHEELCHAIR} info={info} label={t('Esteetön ajoneuvo')} onChange={onChange} state={state} />
            <Restriction id={SEDAN} info={info} label={t('Henkilöauto')} onChange={onChange} state={state} />
          </>
        )}
      </Col>
      <Col sm={6}>
        <h4>{t('Lisätarpeet')}</h4>
        {isKela ? (
          <Restriction id={STAIRCASE_HELPER} depends={WHEELCHAIR} label={t('Porrasveto')} onChange={onChange} state={state} />
        ) : (
          <Restriction id={STAIRCASE_HELPER} info={info} label={t('Porrasveto')} onChange={onChange} state={state} />
        )}
        <Restriction id={GUIDE_DOG} label={t('Opaskoira')} onChange={onChange} state={state} />
        {isKela ? (
          <Restriction id={TRAVELS_ALONE} disabled label={t('Yksinmatkustusoikeus')} state={state} />
        ) : null}
      </Col>
    </>
  )
}

export default TravelRestrictions
