import React, { useEffect, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import GoogleMapReact from 'google-map-react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'


import styles from './LocationPicker.module.css'


const searchOptions = {
  componentRestrictions: {country: 'fi'},
  types: ['address'],
}

const defaultPosition = {lat: 60.19, lng: 24.95}


const LocationPicker = ({ onChange, saved, type, value }) => {
  const [ loaded, setLoaded ] = useState(false)
  const [ search, setSearch ] = useState(value || '')
  const [ selected, setSelected ] = useState(true)

  useEffect(() => {
    setSearch(value || '')
    setSelected(true)
  }, [value])

  const handleSearch = (s) => {
    setSearch(s)
    setSelected(false)
  }

  const handleSelect = (e) => {
    return geocodeByAddress(e).then((a) => {
      setSelected(true)
      onChange(a[0], type)
    })
  }

  const handleSaved = ({ city, street }) => {
    setSelected(true)
    onChange({
      city,
      formatted_address: street + ', ' + city,
      street,
    }, type)
  }

  const handleLoaded = ({ map, maps }) => {
    setLoaded({ map, maps })
  }

  const handleBlur = () => {
    if (!selected) {
      handleSelect(search).catch(() => {
        setSearch('')
        onChange('', type)
      })
    }
  }

  const savedMatches = search ? (
    saved.filter((s) => (
      s.street.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      s.city.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    ))
   ) : []

  return (
    <div className={styles.LocationPicker} onBlur={handleBlur}>
      <div style={{display: 'none'}}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCy0y85uFsrLi-Yyfzg_-r_eGKP0t0XnqI', language: 'fi', region: 'fi', libraries: ['places'] }}
          defaultCenter={defaultPosition}
          defaultZoom={10}
          onGoogleApiLoaded={handleLoaded}
          yesIWantToUseGoogleMapApiInternals
        />
      </div>
      {loaded && onChange ? (
        <PlacesAutocomplete autoFocus id={type} value={search} onChange={handleSearch} onSelect={handleSelect} searchOptions={searchOptions}>
          {({ getInputProps, getSuggestionItemProps, suggestions }) => (
            <Dropdown>
              <Dropdown.Toggle as={Form.Control} {...getInputProps()} />
              {suggestions && (suggestions.length || savedMatches.length) ? (
                <Dropdown.Menu className={styles.Suggestions} show>
                  {savedMatches.map((s) => (
                    <Dropdown.Item key={s.address_id} onMouseDown={() => handleSaved(s)}><strong>{s.street}, {s.city}</strong></Dropdown.Item>
                  ))}
                  {suggestions.map((s, i) => <Dropdown.Item {...getSuggestionItemProps(s)} key={i}>{s.description}</Dropdown.Item>)}
                </Dropdown.Menu>
              ) : null}
            </Dropdown>
          )}
        </PlacesAutocomplete>
      ) : null}
    </div>
  )
}


export default LocationPicker
