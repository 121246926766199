import React, { useState } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import Icon from '../components/Icon'
import Map from '../components/Map'
import NavButton from '../components/NavButton'

import { cancelOrder } from '../api'

import styles from './Order.module.css'


export const DISPATCH = 0
export const ACCEPTED = 1
export const RECALLED = 2
export const PICKEDUP = 3
export const COMPLETE = 4
export const NOJOB = 5
export const NOCARAVAILABLE = 6
export const COVEREDBYALTERNATE = 7
export const CANCELLED = 8
export const REBOOKED = 9
export const AUTODISCARD = 10
export const SPLIT = 11
export const PENDINGREJECTCONFIRMATION = 12
export const DROPPEDOFF = 13
export const MERGED = 14


const canCancel = (status) => (
  +status === 0 ||
  +status === 2 ||
  +status === 9 ||
  +status === 12 ||
  +status === 14
)


export const isComplete = (status) => (
  +status === CANCELLED ||
  +status === COMPLETE ||
  +status === DROPPEDOFF
)


const OrderStatus = ({ children, status }) => (
  <div className={status >= 4 ? styles.Complete : styles.Waiting}>
    {children}
  </div>
)


const Reorder = ({ children, order_number }) => (
  <div className={styles.Reorder}>
    <NavButton to={'/app/order/' + order_number} variant="success">{children}</NavButton>
  </div>
)


const Cancel = ({ children, order_id }) => {
  const [ error, setError ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const history = useHistory()
  const { t } = useTranslation()

  const handleClick = () => {
    setError(false)
    setLoading(true)
    cancelOrder(order_id).then((r) => {
      setLoading(false)
      if (r.ok) {
        history.push('/app/orders/refresh')
      } else {
        setError(true)
      }
    })
  }

  return (
    <div className={styles.Cancel}>
      <Button disabled={loading} onClick={handleClick} variant="danger">{children}</Button>
      {error ? (
        <Alert variant="danger">{t('Peruutus epäonnistui')}</Alert>
      ) : null}
    </div>
  )
}


const OrderHead = ({ address, title }) => {
  return (
    <>
      <h2>{title}</h2>
      <Map position={{lat: address.latitude, lng: address.longitude}} />
    </>
  )
}


const Order = ({ condensed, destination_address, dispatch_order_number, order_id, order_status, pickup_address, preorder_time, submitted }) => {
  const { t } = useTranslation()
  const dateTime = DateTime.fromISO((preorder_time || submitted) + 'Z', {zone: 'Europe/Helsinki'})
  const order_number = dispatch_order_number || order_id
  const title = t('Matka') + ' ' + order_number

  const status_text = {
    '0': t('Odottaa auton kuittausta'),
    '1': t('Taksi on matkalla'),
    '2': t('Odottaa auton kuittausta'),
    '3': t('Mukavaa matkaa!'),
    '4': t('Matka on päättynyt'),
    '5': t('Tilaus keskeytetty'),
    '6': t('Tilaus keskeytetty'),
    '7': t('Taksi on matkalla'),
    '8': t('Tilaus keskeytetty'),
    '9': t('Odottaa auton kuittausta'),
    '10': t('Tilaus keskeytetty'),
    '11': t('Taksi on matkalla'),
    '12': t('Odottaa auton kuittausta'),
    '13': t('Matka on päättynyt'),
    '14': t('Odottaa auton kuittausta'),
  }

  return (
    <div className={styles.Order}>
      {!condensed ? (
        <OrderHead address={pickup_address} title={title} />
      ) : null}
      <div className={styles.Date}>{dateTime.toFormat('d.M.y')}</div>
      <OrderStatus status={order_status}>
        {status_text[order_status] || status_text['4']}
      </OrderStatus>
      <Row className={styles.OrderDetails}>
        <Col sm={6}>
          <Icon type="time" />
          <div className={styles.DetailsBox}>
            <strong>{t('Klo')} {dateTime.toFormat('HH.mm')}</strong><br />
            <small>{t('Arvioitu aika')}</small>
          </div>
        </Col>
        <Col sm={6}>
          <Icon type="destination" />
          <div className={styles.DetailsBox}>
            <strong>{destination_address.street}</strong><br />
            <small>{destination_address.city}</small>
          </div>
        </Col>
        <Col sm={6}>
          <Icon type="start" />
          <div className={styles.DetailsBox}>
            <strong>{pickup_address.street}</strong><br />
            <small>{pickup_address.city}</small>
          </div>
        </Col>
        <Col sm={6}>
          <Icon type="info" />
          <div className={styles.DetailsBox}>
            <Link to={'./' + order_number}><strong>{title}</strong></Link><br />
            <small>{pickup_address.city}</small>
          </div>
        </Col>
      </Row>
      {+order_status === 4 ? (
        <Reorder order_number={order_number}>{t('Tilaa uudelleen')}</Reorder>
      ) : (
        canCancel(order_status) && dispatch_order_number ? (
          <Cancel order_id={dispatch_order_number}>{t('Peruuta tilaus')}</Cancel>
        ) : null
      )}
    </div>
  )
}

export default Order
