import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'


import styles from './Map.module.css'


const Map = ({ position }) => {
  const [ loaded, setLoaded ] = useState(null)
  const [ marker, setMarker ] = useState(null)

  const handleMove = (e) => {
     // Do nothing, but allow move
  }

  const handleLoaded = ({ map, maps }) => {
    if (marker) {
      marker.setMap(null)
    }
    setLoaded({map, maps})
    setMarker(new maps.Marker({ position, map }))
  }

  useEffect(() => {
    if (loaded) {
      const { map, maps } = loaded
      if (marker) {
        marker.setMap(null)
      }
      setMarker(new maps.Marker({ position, map }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, position])

  return (
    <div id="Map">
      <div id={styles.Container}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCy0y85uFsrLi-Yyfzg_-r_eGKP0t0XnqI', language: 'fi', region: 'fi', libraries: ['places'] }}
          center={position}
          defaultZoom={15}
          onChange={handleMove}
          onGoogleApiLoaded={handleLoaded}
          yesIWantToUseGoogleMapApiInternals
          />
      </div>
    </div>
  )
}


export default Map
