import React from 'react'
import { Form } from 'react-bootstrap'

import { SEDAN, STRETCHER, WHEELCHAIR } from './TravelRestrictions'


const isRadio = (id) => (id === SEDAN || id === STRETCHER || id === WHEELCHAIR)


const Restriction = ({ depends, disabled, id, info, label, onChange, radio, state }) => {
  const getRestriction = (type, properties) => {
    const prop = properties.find((p) => p.sote_property_id === type)
    return prop ? prop.value : false
  }

  radio = radio === undefined ? isRadio(id) : radio

  const handleRestriction = () => {
    if (radio && isRadio(id)) {
      const sote_properties = state.sote_properties.filter(
        (p) => !isRadio(p.sote_property_id)
      ).concat({
        sote_property_id: id,
        value: true,
      })
      onChange({sote_properties})
    } else {
      const prev = state.sote_properties.find((p) => p.sote_property_id === id)
      const sote_properties = state.sote_properties.filter(
        (p) => p.sote_property_id !== id
      ).concat({
        sote_property_id: id,
        value: prev ? !prev.value : true,
      })
      onChange({sote_properties})
    }
  }

  const disable = (
    !!disabled ||
    (!!info && !getRestriction(id, info.customer_specific_restrictions)) ||
    (depends ? !getRestriction(depends, state.sote_properties) : false)
  )

  return (
    <Form.Group controlId={'restriction_' + id}>
      <Form.Check
        checked={getRestriction(id, state.sote_properties)}
        disabled={disable}
        type={radio ? 'radio' : 'checkbox'}
        label={label}
        onChange={handleRestriction}
      />
    </Form.Group>
  )
}

export default Restriction
