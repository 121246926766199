import React, { useContext } from 'react'
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import Navi from '../components/Navi'

import Home from './Home'
import Logout from './Logout'
import Order from './Order'
import Orders from './Orders'
import UserInfo from './UserInfo'

import { LoginContext } from '../App'

import styles from './Main.module.css'


const View = ({ active, children }) => (
  <>
    <Navi active={active} />
    <div className={styles.Main}>
      {children}
    </div>
  </>
)


const Main = () => {
  const loginState = useContext(LoginContext)

  if (!loginState.loggedIn) {
    return <Redirect to="/login/start" />
  }

  return (
    <Switch>
      <Route exact path="/app/home">
        <View active="home">
          <Home />
        </View>
      </Route>
      <Route exact path="/app/logout">
        <View active="home">
          <Logout />
        </View>
      </Route>
      <Route exact path="/app/user">
        <View active="user">
          <UserInfo />
        </View>
      </Route>
      <Route exact path={['/app/order', '/app/order/:id']}>
        <View active="order">
          <Order />
        </View>
      </Route>
      <Route exact strict path="/app/orders">
        <Redirect to="/app/orders/" />
      </Route>
      <Route exact path={['/app/orders/', '/app/orders/:id']}>
        <View>
          <Orders />
        </View>
      </Route>
      <Route>
        <View>
          404
        </View>
      </Route>
    </Switch>
  )
}

export default Main
