import React, { useState, useEffect, useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Loading from '../components/Loading'
import TravelRight from '../components/TravelRight'
import UserField from '../components/UserField'

import { travelRights, userInfo } from '../api'
import { LoginContext } from '../App'

import styles from './UserInfo.module.css'


const UserInfo = () => {
  const [ user, setUser ] = useState(null)
  const [ travel, setTravel ] = useState(null)
  const { t } = useTranslation()
  const { soteArea } = useContext(LoginContext)

  useEffect(() => {
    userInfo()
    .then(r => r.json())
    .then(r => setUser(r.customer))
  }, [setUser])
  
  useEffect(() => {
    travelRights()
    .then(r => r.json())
    .then(r => setTravel(r.travel_right))
  }, [setTravel])
  
  if (!user) {
    return <Loading />
  }


  const a = user.address[0]
  const address = (
    <span>
      {a.street} <br />
      {a.postal_code} {a.city}
    </span>
  )

  const languages = {
    fi: t('Suomi'),
    se: t('Ruotsi'),
    unknown: t('Ei tuettu')
  }

  const language = languages[user.language.toLowerCase()] || languages.unknown

  return (
    <div id={styles.UserInfo}>
      <h2>{t('Omat tiedot')}</h2>
      <Row>
        <UserField label={t('Etu- ja sukunimi')} value={user.first_name + ' ' + user.last_name} />
        <Col className="d-block d-sm-none" sm={6}><hr /></Col>
        <UserField label={t('Syntymäpäivä')} value={user.date_of_birth} />
        <Col className="d-none d-sm-block" sm={6}><hr /></Col>
        <Col sm={6}><hr /></Col>
        <UserField label={t('Osoite')} value={address} />
        <Col className="d-block d-sm-none" sm={6}><hr /></Col>
        <UserField label={t('Kieli')} value={language} />
        <Col className="d-none d-sm-block" sm={6}><hr /></Col>
        <Col sm={6}><hr /></Col>
      </Row>
      {travel && travel.length ? (
        <h3>{t('Matkaoikeudet')}</h3>
      ) : null}
      {travel && travel.length ? (
        <Row>
          {travel.map(tr => (
            <TravelRight key={tr.travel_right_id} label={t('VPL Työmatkat')} {...tr} />
          ))}
        </Row>
      ) : null}
      {travel ? null : <Loading />}
      <Row>
        {travel && travel.length ? (
          <Col sm={12}><hr /></Col>
        ) : null}
        <Col sm={12}>
          <a href={soteArea.contactsUrl} rel="noopener noreferrer" target="_blank">
            {t('Ohjeet tietojen korjaamiseen')}
          </a>
        </Col>
      </Row>
    </div>
  )
}

export default UserInfo
