import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './Icon.module.css'


const icons = {
  destination: {src: '/destination.png', alt: 'Määränpää', height: '27px', width: '20px'},
  info: {src: '/info.png', alt: 'Info', height: '22px', width: '22px'},
  start: {src: '/start.png', alt: 'Nykyinen sijainti', height: '27px', width: '20px'},
  time: {src: '/time.png', alt: 'Ajankohta', height: '22px', width: '22px'},
}


const Icon = ({ type }) => {
  const { t } = useTranslation()

  const icon = icons[type] || icons.info

  if (type === 'placeholder') {
    return <span className={styles.Placeholder}>&nbsp;</span>
  }

  return (
    <img
      {...icon}
      alt={t(icon.alt)}
      className={styles.Icon}
    />
  )
}

export default Icon
