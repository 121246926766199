import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import sv from './locales/sv/translation.json'


i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'fi',
    lng: 'fi',
    debug: false,

    interpolation: {
      escapeValue: false,
    },
    keySeparator: '|',
    namespaceSeparator: '~',

    resources: {sv: {translation: sv}}
  })


export default i18n
