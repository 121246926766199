import React, { useContext, useState } from 'react'
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { Button, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import NavButton from '../components/NavButton'

import { ACTION_LOGGING_IN, LoginContext } from '../App'

import { getActivationCode, logIn, phoneLogin, setActivationCode } from '../api'


const Welcome = () => {
  const { t } = useTranslation()
  return (
    <>
      <h2>{t('Tervetuloa käyttämään matkapalvelua')}</h2>
      <p>{t('Jatka sovelluksen käyttöönottoa aloittamalla tunnistautuminen')}</p>
      <NavButton to="/login/login" variant="success">{t('Aloita tunnistautuminen')}</NavButton>
    </>
  )
}


const PhoneLogin = ({ onLogin }) => {
  const { t } = useTranslation()
  const [ phone, setPhone ] = useState('')
  const [ error, setError ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ validating, setValidating ] = useState(false)
  const [ code, setCode ] = useState('')

  const handlePhone = (e) => {
    setPhone(e.target.value)
  }

  const handleCode = (e) => {
    setCode(e.target.value)
  }

  const handleSubmitPhone = (e) => {
    e.preventDefault()
    const normalized = (
      phone.startsWith('0') ? '+358' + phone.slice(1) : phone
    ).replace(' ', '').replace('-', '')
    setLoading(true)
    setError(false)
    getActivationCode(normalized).then((r) => {
      return r.json()
    }).then((r) => {
      setPhone(r.phone_number)
      setValidating(true)
      setLoading(false)
    }).catch((e) => {
      setError(true)
      setLoading(false)
    })
  }

  const handleSubmitCode = (e) => {
    e.preventDefault()
    setLoading(true)
    setError(false)
    setActivationCode(code).then((r) => {
      return r.json()
    }).then((r) => {
      return phoneLogin(r.login_token)
    }).then((r) => {
      return r.json()
    }).then((r) => {
      logIn(r.session_id)
      onLogin()
    }).catch((e) => {
      console.log(e)
      setError(true)
      setLoading(false)
    })
  }

  if (validating) {
    return (
      <>
        <h2>{t('Vahvistuskoodi')}</h2>
        <Form onSubmit={handleSubmitCode}>
          <Col md={{span: 6, offset: 3}}>
            <Form.Group>
              <Form.Label>{t('Lähetimme sinulle tekstiviestin joka sisältää vahvistuskoodin. Syötä se tähän ja paina lopuksi Vahvista puhelinnumeroni -painiketta.')}</Form.Label>
              <Form.Control disabled={loading} type="text" placeholder="0000" value={code} onChange={handleCode} />
              {error ? <Form.Text>{t('puhelinnumeroa ei hyväksytty')}</Form.Text> : null}
            </Form.Group>
          </Col>
          <Button disabled={loading} type="submit" variant="success">{t('Vahvista puhelinnumeroni')}</Button>
        </Form>
      </>
    )
  }

  return (
    <>
      <h2>{t('Tunnistautuminen')}</h2>
      <Form onSubmit={handleSubmitPhone}>
        <Col md={{span: 6, offset: 3}}>
          <Form.Group>
            <Form.Label>{t('Aloita lisäämällä puhelinnumerosi allaolevaan tekstikenttään. Paina lopuksi Jatka-painiketta.')}</Form.Label>
            <Form.Control disabled={loading} type="phone" placeholder="puhelinnumero" value={phone} onChange={handlePhone} />
            {error ? <Form.Text>{t('puhelinnumeroa ei hyväksytty')}</Form.Text> : null}
          </Form.Group>
        </Col>
        <Button disabled={loading} type="submit" variant="success">{t('Jatka vahvistukseen')}</Button>
      </Form>
    </>
  )
}


const Login = () => {
  const loginState = useContext(LoginContext)

  if (loginState.loggedIn) {
    return <Redirect to="/app/home" />
  }

  const handleLogin = () => {
    loginState.dispatch({
      type: ACTION_LOGGING_IN,
    })
  }

  return (
    <div style={{textAlign: 'center'}}>
      <header style={{backgroundColor: '#252525', marginBottom: '25px', padding: '50px'}}>
        <img alt="taksihelsinki logo" src="/th-logo.png" width="224px" height="25px"/>
      </header>
      <Switch>
        <Route path="/login/start">
          <Welcome />
        </Route>
        <Route path="/login/login">
          <PhoneLogin onLogin={handleLogin} />
        </Route>
        <Route>
          404
        </Route>
      </Switch>
    </div>
  )
}

export default Login
