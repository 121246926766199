import React, { createContext, useEffect, useReducer } from 'react'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Login from './views/Login'
import Main from './views/Main'

import Loading from './components/Loading'
import { loggedIn, soteAreaInfo, userInfo } from './api'
import styles from './App.module.css'


export const AREA_SOITE = 2
export const AREA_KELA = 3

const initialLoginState = {
  loggedIn: null,
  soteArea: {},
}

export const LoginContext = createContext(initialLoginState)

export const ACTION_LOGGING_IN = 'action/logging_in'
export const ACTION_LOGIN = 'action/login'
export const ACTION_LOGOUT = 'action/logout'

const loginReducer = (state, action) => {
  switch (action.type) {
    case ACTION_LOGGING_IN:
      return {loggedIn: undefined, soteArea: {}}
    case ACTION_LOGIN:
      return {loggedIn: true, soteArea: action.soteArea || {}}
    case ACTION_LOGOUT:
      return {loggedIn: false, soteArea: {}}
    default:
      return initialLoginState
  }
}


const App = () => {
  const [ state, dispatch ] = useReducer(loginReducer, initialLoginState)
  const { i18n } = useTranslation()

  useEffect(() => {
    if (!state.loggedIn) {
      if (loggedIn()) {
        soteAreaInfo().then(r => r.json()).then(soteArea => {
          dispatch({
            type: ACTION_LOGIN,
            soteArea,
          })
          userInfo().then((r) => r.json()).then((r) => {
            if (r.customer.language.toLowerCase().startsWith('se')) {
              i18n.changeLanguage('sv')
            }      
          })
        })
      } else if (state.loggedIn !== false) {
        dispatch({
          type: ACTION_LOGOUT,
        })
      }
    }
  }, [i18n, state, dispatch])

  if (!state.loggedIn && state.loggedIn !== false) {
    return <Loading />
  }

  return (
    <BrowserRouter>
      <LoginContext.Provider value={{...state, dispatch}}>
        <div id={styles.SoteApp}>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/app">
              <Main />
            </Route>
            <Route path="/">
              <Redirect to="/app/home" />
            </Route>
            <Route path="/index">
              <Redirect to="/app/home" />
            </Route>
          </Switch>
        </div>
      </LoginContext.Provider>
    </BrowserRouter>
  )
}

export default App
